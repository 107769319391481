import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LogoutButton } from '../login/Logout';
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import { imageToBase64, resizeImage } from '../../functions/utils';
import { do_post } from '../../functions/http_client';

export class Menu extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.state = {
            edit_logo: false,
            selected_logo: ""
        }
    }

    componentDidMount(){
        if(this.props.userProfile && this.props.userProfile.logo){
            this.setState({selected_logo: this.props.userProfile.logo})
        }
    }

    fileInput = React.createRef();

    handlePictureUploadClick = () => {
        this.fileInput.current.click();
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        resizeImage(file, 128, 128).then(resizedImageUrl => {
          this.setState({selected_logo: resizedImageUrl});
        });
      };

    saveChangeLogo = () => {
        let logo = this.state.selected_logo
        if(this.state.selected_logo && this.state.selected_logo.startsWith("http")){
            imageToBase64(this.state.selected_logo, base64 => {logo = base64})
        }
        do_post("/update-logo", "text/plain", {"Authorization": this.props.userProfile.token}, logo, () => {this.setState({edit_logo: false}); this.props.refresh_profile()})
    }

    render(){
        const profilePicture = this.props.userProfile?(this.props.userProfile.logo?this.props.userProfile.logo:this.props.userProfile.picture):"";
        const uploadPicture = this.state.selected_logo?this.state.selected_logo:(this.props.userProfile&&this.props.userProfile.logo)?this.props.userProfile.logo:'/images/photo-album.png';

        return <>
            {this.state.edit_logo && 
                <Modal show={this.state.edit_logo} onHide={() => this.setState({edit_logo: false})} size='xl'>
                    <Modal.Body style={{textAlign: 'center', marginTop: "2em"}}>
                        <Image onClick={this.handlePictureUploadClick} src={uploadPicture} style={{width: '128px', cursor: 'pointer'}} rounded />                        
                        <Form.Group style={{textAlign: 'center'}}>
                            <Form.Label htmlFor="formFile"><img src="/images/upload.png" width="32px" /> Upload Logo</Form.Label>
                            <Form.Control type='text' placeholder='Browse URL' value={this.state.selected_logo.startsWith("http")?this.state.selected_logo:""} onChange={(e) => this.setState({selected_logo: e.target.value})} />
                            <Form.Control id="formFile" ref={this.fileInput} style={{display: 'none'}} type="file" onChange={this.handleFileChange} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.saveChangeLogo}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            <Navbar className="bg-body-tertiary align-items-center header" style={{paddingLeft: "2em", paddingRight: "2em"}}>
                    <Nav className="me-auto d-flex flex-grow-1 align-items-center">
                        <img src="/images/logo7.png" width="64px" />&nbsp;
                        <h2 className="header-h2">PYXIDA</h2>
                    </Nav>
                    <Navbar.Collapse className="justify-content-end">
                    {this.props.userProfile && <NavDropdown className="user-menu-dropdown" drop="down-centered" title={this.props.userProfile && (
                            <Row>
                                <Col><img width="48px"
                                src={profilePicture}
                                className="dropdown-user-image"/></Col>
                                <Col>
                                    <span className="dropdown-user-name">{this.props.userProfile.name}</span><br/>
                                    <span className="dropdown-user-email">{this.props.userProfile.email}</span>
                                </Col>
                            </Row>
                        )} id="basic-nav-dropdown">
                        <LogoutButton logout={this.props.logout} />
                        <NavDropdown.Item onClick={() => this.setState({edit_logo: true})}><img src={uploadPicture} width="32px" style={{marginRight:"1em"}} />Update Logo</NavDropdown.Item>
                    </NavDropdown>
                    }
                    </Navbar.Collapse>
            </Navbar>
        </>
        
    }

}