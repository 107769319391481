import { useGoogleLogin } from '@react-oauth/google';

import { useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

function differenceInSeconds(timestamp) {
    const date1 = new Date(timestamp);
    const now = new Date();
    const difference = date1.getTime() - now.getTime();
    return Math.abs(difference / 1000);
}

function addSecondsToDate(date, seconds){
    date.setSeconds(date.getSeconds() + seconds);
}

export function LoginButton(props) {

    const scheduleTokenRefresh = (expiresIn) => {
        setTimeout(() => {
            login();
        }, (expiresIn - 300) * 1000);
    }

    useEffect(() => {
        if (props.accessToken) {
            const expiresIn = differenceInSeconds(props.expirationTime);
            scheduleTokenRefresh(expiresIn);
        }
    }, [props.accessToken]);


    const onSuccess = (response) => {
        console.log("useGoogleLogin Got "+JSON.stringify(response))
        const expiration_time = addSecondsToDate(new Date(), response.expires_in)
        props.setAccessToken(response.access_token, expiration_time)
        scheduleTokenRefresh(response.expires_in)
    }

    const login = useGoogleLogin({
        onSuccess: codeResponse => onSuccess(codeResponse),
        flow: 'implicit',
        hint: (props.userProfile && props.userProfile.email)?props.userProfile.email:'',
        prompt: (props.userProfile && props.userProfile.email)?'none':'select_account',
        select_account: !props.userEmail
    });

    return <>
        {!props.accessToken && <Container fluid style={{minHeight: "90vh"}} className="d-flex align-items-center justify-content-center">
            <Row>
                <Col>
                    <div className="text-center" id="login-button">
                        <Button onClick={() => login()}>Sign in with Google 🚀</Button>
                    </div>
                </Col>
            </Row>
        </Container>}
        </>

}