import React from 'react';
import {LoginButton} from "../login/LoginButton"
import { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {Menu} from "../header/menu"

import '../../App.css';
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { do_get, do_post } from '../../functions/http_client';

import { useRef } from 'react';
import html2pdf from 'html2pdf.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

const clientId = "778297962983-3s031550r73it895bn3rl7k9ucf536c6.apps.googleusercontent.com";

function getEasterDate(year) {
    let f = Math.floor,
        G = year % 19,
        C = f(year / 100),
        H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
        I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
        J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
        L = I - J,
        month = 3 + f((L + 40) / 44),
        day = L + 28 - 31 * f(month / 4);
    return new Date(year, month - 1, day);
}

function getHolidays(year) {
    const holidays = [];

    // Jours fixes
    holidays.push(new Date(year, 0, 1));
    holidays.push(new Date(year, 4, 1));
    holidays.push(new Date(year, 4, 8));
    holidays.push(new Date(year, 6, 14));
    holidays.push(new Date(year, 7, 15));
    holidays.push(new Date(year, 10, 1));
    holidays.push(new Date(year, 10, 11));
    holidays.push(new Date(year, 11, 25));

    // Jours mobiles
    let easter = getEasterDate(year);
    let lundiPaques = new Date(easter);
    lundiPaques.setDate(easter.getDate() + 1);
    holidays.push(lundiPaques);

    let ascension = new Date(easter);
    ascension.setDate(easter.getDate() + 39);
    holidays.push(ascension);

    let pentecote = new Date(easter);
    pentecote.setDate(easter.getDate() + 50);
    holidays.push(pentecote);

    return holidays.map(dt => formatDateToYYYYMMDD(dt));
}

function formatDateToYYYYMMDD(date) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}


class CraPage extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            bank_holiday: getHolidays(new Date().getFullYear()),
            entries: [],
            edited_entries: [],
            selectedPeriod: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-01`,
            edition_enabled: false,
            loading: true
        }

        this.contentRef = React.createRef();
    }

    componentDidMount(){
        do_get("/get-user-activity", {"Authorization": this.props.userProfile.token}, data => this.setState({entries: data, edited_entries: data, loading: false}))
    }

    generateMonthsUntilTodayPlus6() {
        let result = [];
        let date = new Date("2024-09-01");
        let today = new Date();
        today.setMonth(today.getMonth() + 6);
        while (date <= today) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            result.push(`${year}-${month}-01`);
            date.setMonth(date.getMonth() + 1);
        }
        return result;
    }

    formatDateToMonthYear(dateString) {
        const months = [
            "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
            "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
        ];
        let date = new Date(dateString);
        let year = date.getFullYear();
        let month = months[date.getMonth()];
        return `${month} ${year}`;
    }

    weekd_days = ["L", "M", "M", "J", "V", "S", "D"];
    generateDaysForSelectedMonth(period){
        let result = [];
        let end_date = new Date(period);
        end_date.setMonth(end_date.getMonth() + 1);
        let curdate = new Date(period);
        while (curdate < end_date){
            result.push(this.weekd_days[(curdate.getDay()+6) % 7])
            curdate.setDate(curdate.getDate() + 1)
        }
        return result;
    }
    
    changeCraValue(username, date, oldValue){
        let newValue = oldValue==1?0.5:oldValue==0?1:0
        let updatedEntries = this.state.edited_entries.map(e => {
            if(e.name == username) return {...e, activity: {...e.activity, [date]: newValue}}
            else return e
        })
        this.setState({edited_entries: updatedEntries})
    }

    saveCra = () => {
        const entry = this.state.edited_entries.find(e => e.email == this.props.userProfile.email)
        if(entry){
            this.setState({loading: true})
            do_post(
                "/update-user-activity", 
                "application/json",
                {"Authorization": this.props.userProfile.token},
                JSON.stringify({
                    ...entry,
                    activity: Object.entries(entry.activity).filter(a => a[1] > 0).reduce((accum, [k, v]) => {
                        accum[k] = v;
                        return accum;
                      }, {})
                }),
                () => {
                    this.setState({edition_enabled: false, loading: false})
                }
            )
        }
    }

    export_pdf = () => {
        const options = {
            filename: `cra-${this.state.selectedPeriod.substring(0,7)}.pdf`,
            margin: [0.5, 0.5, 0.5, 0.5],
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
        };

        document.getElementById("cra-header").style.display = "block";
        document.getElementById("cra-period-selector").style.display = "none";
        document.getElementsByClassName("user-entry").forEach(element => element.style.marginTop = "1em")
        document.getElementById("header-period").innerHTML=" "+this.formatDateToMonthYear(this.state.selectedPeriod)
        document.getElementById("export_time").innerHTML="Exporté le "+new Date().toLocaleString();
        html2pdf().set(options).from(document.getElementById('printable')).save().then(() => {
            document.getElementById("cra-header").style.display = "none";
            document.getElementById("cra-period-selector").style.display = "block";
            document.getElementById("header-period").innerHTML=""
            document.getElementsByClassName("user-entry").forEach(element => element.style.marginTop = "4em")
        });
        
    }

    render(){
        const months = this.generateMonthsUntilTodayPlus6()
        const days_in_month = this.generateDaysForSelectedMonth(this.state.selectedPeriod)

        const calendar_rows = [[]];
        for(const day in days_in_month){
            const to_update = days_in_month[day]=="L"?[]:calendar_rows.pop();
            to_update.push(days_in_month[day])
            calendar_rows.push(to_update)
        }
        
        function range(start, end){
            const result = [];
            while(start < end){
                result.push(start);
                start++;
            }
            return result;
        }

        const export_logo = (this.props.userProfile&&this.props.userProfile.logo)?this.props.userProfile.logo:"/images/logo7.png";

        return <Container ref={this.contentRef} style={{marginTop: "6em"}}>
                <div id="printable" style={{position: "relative"}}>
                    <Row id="cra-header" className='align-items-center' style={{marginBottom: "2em", display: "none", textAlign: "center"}}>
                        <img src={export_logo} style={{width: "96px", position: "absolute", left: "0"}}/><h1>Compte Rendu d'Activité<span id="header-period"></span></h1>
                        <i style={{fontSize: "14px", color: "#aaa"}} id="export_time"></i>
                    </Row>
                    <div id="cra-period-selector">
                        <FontAwesomeIcon icon={faCalendarDays} size="2x" style={{color: "#0563af", marginRight: "0.3em"}} />
                        <select onChange={(e) => this.setState({selectedPeriod: e.target.value, bank_holiday: getHolidays(new Date(e.target.value).getFullYear())})}>
                            {months.map(month =>
                                <option className={month == this.state.selectedPeriod?"selected-period":""} selected={month == this.state.selectedPeriod} value={month}>{this.formatDateToMonthYear(month)}</option>
                            )}
                        </select>
                    </div>
                    

                    {this.state.edited_entries.map(entry =>
                        <div className="user-entry" style={{textAlign: "center", marginTop: "4em"}}>
                            <h3>{entry.name}</h3>

                            <Table style={{textAlign: "center", marginTop: "2em"}}>
                                <thead>
                                    {this.weekd_days.map((letter) => {
                                        return <th style={{backgroundColor: ["S","D"].includes(letter)?"lightblue":""}}>{letter}</th>
                                    })}
                                </thead>
                                <tbody>                           
                                    {calendar_rows.filter(arr => arr.length > 0).map((letters, rowId) => {
                                        //console.log("Adding row "+rowId+" with empty cols : "+(rowId==0?range(0, 7 - letters.length):letters.length==7?[]:range(0, 7 - letters.length)).length)
                                        return <tr>
                                            {(rowId==0?range(0, 7 - letters.length):[]).map(i => <td></td>)}
                                            {calendar_rows[rowId].map((i,idx) => {
                                                const daynumber=rowId==0?idx+1:((rowId-1) * 7 + idx+1 + calendar_rows[0].length) //- (7-calendar_rows[0].length);
                                                let date = new Date(this.state.selectedPeriod)
                                                date.setDate(date.getDate() + daynumber-1)
                                                date = formatDateToYYYYMMDD(date)
                                                const value = entry.activity[date]?entry.activity[date]:0

                                                let backgroundColor = "";
                                                if(value == 1){
                                                    backgroundColor = "#ec3";
                                                } else if(value == 0.5){
                                                    backgroundColor = "#ea1";
                                                } else if(["S","D"].includes(days_in_month[daynumber-1]) || this.state.bank_holiday.includes(date)){
                                                    backgroundColor = "lightblue";
                                                }
                                                
                                                return daynumber-1<days_in_month.length?<td 
                                                style={{
                                                    margin: "1em",
                                                    /*background: (["S","D"].includes(days_in_month[daynumber-1])?"lightblue":this.state.bank_holiday.includes(date))?"repeating-linear-gradient(-45deg, white, lightblue 2%)":value==1?"#ec3":value==0.5?"#ea1":"#eee",*/
                                                    background: this.state.bank_holiday.includes(date)?`repeating-linear-gradient(-45deg, white, ${backgroundColor} 2%)`:backgroundColor,
                                                    cursor: this.state.edition_enabled?"pointer":""
                                                }} onClick={() => {this.state.edition_enabled && this.changeCraValue(entry.name, date, value)}}><span className='daynumber'>{daynumber}</span><br/>{value} JH</td>:<td></td>
                                            })}
                                        </tr>
                                    })}
                                    
                                </tbody>
                            </Table>

                            <h4>Total : {Object.entries(entry.activity).filter(entry => entry[0].startsWith(this.state.selectedPeriod.substring(0,7))).map(entry => entry[1]).reduce((accumulator, currentValue) => {
                                            return accumulator + currentValue
                                        },0)} JH</h4>
                        </div>
                    )}
                </div>

                <Row style={{marginTop: "4em"}}>
                        {this.state.loading && 
                            <Spinner />
                        || (this.state.edition_enabled && 
                            <>
                                <Col><Button onClick={() => this.setState({edition_enabled: false, edited_entries: this.state.entries})} variant="outline-danger">
                                    <img src="/images/cancel.png" width="32px" /> Cancel</Button>
                                </Col>
                                <Col><Button onClick={() => this.saveCra()} variant="outline-success">
                                    <img src="/images/check-mark.png" width="32px" /> Save</Button></Col>
                            </> ||
                            <Col>
                                <Button onClick={() => this.setState({edition_enabled: true, edited_entries: this.state.entries})} variant='outline-primary'>
                                    <img src="/images/edit.png" width="32px" /> Edit
                                </Button>
                            </Col>)
                        }
                </Row>

                <Row style={{marginTop: "4em"}}>
                    <Button variant="outline-danger" onClick={() => this.export_pdf()}><img src="/images/pdf.png" width="32px" /> PDF Export</Button>
                </Row>
        </Container>
    }
}

export default class CraApp extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            token: '',
            expiration_time: '',
            userProfile: null
        }
    }


    isTokenValid = async (token) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
    
        try {
            const response = await fetch("https://oauth2.googleapis.com/tokeninfo?access_token=" + token, requestOptions);
            console.log(response.ok)
            return response.ok;
        } catch (error) {
            console.error('error', error);
            return false;
        }
    }
    
    componentDidMount = async () => {
        const token = localStorage.getItem('userToken');
        const isTokenValid = await this.isTokenValid(token)
        if(token && isTokenValid){
          console.log("Setting token to "+token)
          const expiration_time = localStorage.getItem('expiration_time');
          const userProfileStr = localStorage.getItem('userProfile');
          this.setState({token: token, expiration_time: expiration_time, userProfile: userProfileStr?JSON.parse(userProfileStr):null})
        }
    }

    extractUserInfo = (token) => {
        /*fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
          method: 'GET',
          cache: 'no-cache',
          headers: {
              Authorization: `Bearer ${token}`,
          }
        })
        .then((response) => {
            if(!response.ok) {
            return response.text().then((text) => {
                throw new Error(text);
            });
            }
            return response.json()
        })
        .then((data) => {
          const userProfile = {...data, token: token};
          localStorage.setItem('userProfile', JSON.stringify(userProfile));
          this.setState({userProfile: userProfile})
        })
        .catch((error) => {
            console.error(error);
        })*/

        this.setState({token: token})
        do_get("/get-user-profile", {"Authorization": token}, (data) => {
            const userProfile = {...data, token: token};
            localStorage.setItem('userProfile', JSON.stringify(userProfile));
            this.setState({userProfile: userProfile})
        })
    }

    setAccessToken = (token, expiration_time) => {
        localStorage.setItem('userToken', token);
        localStorage.setItem('expiration_time', expiration_time);
        this.setState({token: token, expiration_time: expiration_time, userProfile: null})
        if(token != ""){this.extractUserInfo(token)}
      }
    
      logout = () => {
        this.setAccessToken("", "", null)
      }

      render(){
        console.log("Rendering Main page with userProfile = "+JSON.stringify(this.state.userProfile))
        return (
          <GoogleOAuthProvider clientId={clientId} accessType="offline">
            <Menu logout={this.logout} userProfile={this.state.userProfile} refresh_profile={() => this.extractUserInfo(this.state.token)} active_menu={this.state.active_page} goto={(page) => this.setState({"active_page": page})} />
            <div className="App">
              { this.state.userProfile && <CraPage userProfile={this.state.userProfile} /> ||
                <LoginButton accessToken={this.state.token} expirationTime={this.state.expiration_time} userProfile={this.state.userProfile} setAccessToken={this.setAccessToken} />}
            </div>
          </GoogleOAuthProvider>
        );
      }

}