const apiUrl = process.env.REACT_APP_API_URL;

export const do_post = (endpoint, contentType, headers, payload, callback, retries = 0) => {
    if(endpoint.startsWith("/")){
        endpoint = apiUrl+endpoint
    }
    fetch(endpoint, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            ...headers,
            "Content-Type": contentType,
        },
        body: payload
    })
    .then((response) => {
        if(!response.ok) {
            if(retries > 0){
                return do_post(endpoint, contentType, headers, payload, callback, retries - 1);
            } else {
                return response.text().then((text) => {
                    throw new Error(text);
                });
            }
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json()
        } else {
            return response.text()
        }
    })
    .then((response) => callback(response))
}

export const do_get = (endpoint, headers, callback, retries = 0) => {
    if(endpoint.startsWith("/")){
        endpoint = apiUrl+endpoint
    }
    fetch(endpoint, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            ...headers,
            "Accept-Encoding": "application/gzip"
        }
    })
    .then((response) => {
        if(!response.ok) {
            if(retries > 0){
                return do_get(endpoint, callback, retries - 1);
            } else {
                return response.text().then((text) => {
                    throw new Error(text);
                });
            }
        }
        return response.json();
    })
    .then((response) => callback(response))
}
