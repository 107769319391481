import {googleLogout} from '@react-oauth/google';
import NavDropdown from 'react-bootstrap/NavDropdown';

export function LogoutButton(props) {

    const disconnect = () => {
        googleLogout();
        props.logout();
    }

    return (
        <div>
            <NavDropdown.Item onClick={() => disconnect()}><img src="/images/shutdown.png" width="32px" style={{marginRight:"1em"}} />Logout</NavDropdown.Item>
        </div>
    )
}